import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../../../images/usuario.png'


export default function Login() {

    const navigate = useNavigate();

    const [sucursal, setSucursal] = useState('');
    const [cuit, setCuit] = useState('')
    const [password, setPassword] = useState('')

    const [errorMensaje, setErrorMensaje] = useState('')
    const [cargando, setCargando] = useState(false)
    const [showPassword, setShowPassword] = useState(false);


    const sucursales = [
        { nombre: 'Buchardo', value: 'buch' },
        { nombre: 'Integral Sur', value: 'sur' },
        { nombre: 'Laboulaye', value: 'lab' },
        { nombre: 'Serrano', value: 'ser' },
        { nombre: 'Villa Valeria', value: 'val' },
    ];

    const handleSucursalChange = (e) => {
        setSucursal(e.target.value);
        setErrorMensaje('');
    };

    const handleCuitChange = (e) => {
        const cuitIngresado = e.target.value.trim()
        setCuit(cuitIngresado)
        setErrorMensaje('')
    }

    const handlePasswordChange = (e) => {
        const passwordIngresado = e.target.value.trim()
        setPassword(passwordIngresado)
        setErrorMensaje('')
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!sucursal) {
            setErrorMensaje('¡Seleccione Sucursal!');
            return;
        }

        if (!cuit || cuit.trim() === "") {
            setErrorMensaje('¡Ingrese su CUIT!');
            return;
        }

        if (!password || password.trim() === "") {
            setErrorMensaje('¡Ingrese su Contraseña!');
            return;
        }

        setCargando(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login/${sucursal}`, { cuit, password })

            if (response.status === 200) {
                const { token } = response.data
                localStorage.setItem('auth_cliente', token);
                localStorage.setItem('sucursal', sucursal);
                navigate('/layoutCliente');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMensaje(error.response.data.message);
            } else {
                setErrorMensaje('Error al iniciar sesión. Por favor, inténtalo de nuevo.');
            }
        } finally {
            setCargando(false); // Terminar la carga
        }
    }

    const regresarPaginaPrincipal = () => {
        navigate('/')
    }


    return (
        <div className='bg-white  h-screen flex flex-col justify-center items-center'>
            <div className='flex justify-center items-center'>
                <img src={logo} alt="logo" className='lg:w-24 lg:h-24 w-28 h-28' />
            </div>

            <div className='w-80 lg:mt-8 mt-2 '>
                <form onSubmit={handleLogin} className="">
                    <div className="mb-1 md:mb-8 lg:mb-0 md:-mt-6 lg:-mt-6 relative ">
                        <select
                            onChange={handleSucursalChange}
                            value={sucursal}
                            className="bg-slate-100 rounded-sm  py-3 lg:py-1 w-full cursor-pointer font-medium border border-black"
                            required
                            onInvalid={(e) => e.target.setCustomValidity("Por favor, seleccione Sucursal.")}
                            onInput={(e) => e.target.setCustomValidity("")}
                        >
                            <option value="" className='font-medium text-center '>SUCURSALES</option>
                            {sucursales?.map((suc) => (
                                <option className='text-center font-medium ' key={suc.value} value={suc.value}>{suc.nombre}</option>
                            ))}
                        </select>
                    </div>

                    <label htmlFor="username" className='text-black font-medium cursor-pointer '>CUIT</label>
                    <div className="flex  items-center text-lg mb-1 md:mb-8 lg:mb-0 relative">
                        <input
                            onChange={handleCuitChange}
                            type='text'
                            id="username"
                            autoComplete='off'
                            maxLength={11}
                            className="bg-slate-100 rounded-sm pl-2 py-2  lg:py-0.5  md:py-2 focus:outline-none w-full border border-black"
                            required
                            onInvalid={(e) => e.target.setCustomValidity("Por favor, completa este campo con tu CUIT.")}
                            onInput={(e) => e.target.setCustomValidity("")}
                        />
                    </div>

                    <label htmlFor="password" className='text-black font-medium  cursor-pointer '>Contraseña</label>
                    <div className="flex  items-center text-lg mb-4 md:mb-6 lg:mb-4 relative">
                        <input
                            onChange={handlePasswordChange}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete='off'
                            className="bg-slate-100 rounded-sm pl-2 py-2  lg:py-0.5  md:py-2 focus:outline-none w-full border border-black"
                            required
                            onInvalid={(e) => e.target.setCustomValidity("Por favor, completa este campo con tu Contraseña.")}
                            onInput={(e) => e.target.setCustomValidity("")}
                        />
                        <button
                            type="button"
                            onClick={toggleShowPassword}
                            className="absolute right-2 top-2 lg:top-1"
                        >
                            {showPassword ? '👁' : '👁‍🗨'}
                        </button>

                    </div>

                    {errorMensaje && <p className="text-red-600 text-center font-medium text-md mb-2 -mt-2 -lg:mt-4">{errorMensaje}</p>}


                    <button
                        type='submit'
                        disabled={cargando}
                        className={`bg-green-600 hover:bg-green-700 font-medium rounded-sm  p-3 md:p-4 lg:p-1 text-white uppercase w-full transition-transform hover:scale-95 border border-black ${cargando ? 'cursor-not-allowed' : ''}`}>
                        {cargando ? 'CARGANDO...' : 'INGRESAR'}
                    </button>
                    <button
                        type='button'
                        onClick={regresarPaginaPrincipal}
                        className="bg-red-600 hover:bg-red-700 font-medium rounded-sm mt-3 lg:mt-2 p-3 md:p-4 lg:p-1  text-white uppercase w-full transition-transform hover:scale-95 border border-black">
                        VOLVER
                    </button>
                </form>

            </div>
        </div>
    )
}